export const warehouseTranslations = {
  de: {
    consolidate: "Konsolidieren",
    consolidateText:
      "Es besteht bereits ein Artikel {article} in {warehouse}. Soll diesem Artikel die Menge hinzugefügt werden? Falls nicht wird ein identischer Artikel mit der entsprechenden Anzahl erstellt.",
    yesConsolidate:
      "Ja, die Menge soll zum bestehenden Artikel hinzugefügt werden.",
    createSingleArticle: "Einzelartikel erstellen",
    // Notifications
    articleMinimumAmount: "Mindestbestandswarnung",
    articleMinimumAmountDescription:
      "Benachrichtungen erhalten, falls der Mindesbestand eines Artikels im Lager unterschritten wurde. Die Benachrichtigungen können nach Lager gefiltert werden.",
  },
  en: {
    consolidate: "Consolidate",
    consolidateText:
      "An artile {article} already exists in {warehouse}. Should the amount be added to this article? If not an identical article will be created.",
    yesConsolidate: "Yes, add the items to the exisiting article",
    createSingleArticle: "Create single article",
    articleMinimumAmount: "Minimum amount warning",
    articleMinimumAmountDescription:
      "Recieve a notification if the minimum amount threshold of an article has been crossed. These notifications can be filtered by warehouse.",
  },
};
