import { clientId } from "./constants";

export const warehouseRoutes = [
  {
    path: "/warehouse",
    name: "warehouseList",
    props: true,
    component: () =>
      import("@shared/warehouse/views/WarehouseNomenclature.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "inventoryManagement",
          route: { name: "warehouseList" },
        },
      ],
    },
  },
  {
    path: "/warehouse/edit/single/:template/:id?",
    name: "warehouseEditSingle",
    props: true,
    component: () => import("@shared/warehouse/views/WarehouseEditSingle.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "inventoryManagement",
          route: { name: "warehouseList" },
        },
      ],
    },
  },
  {
    path: "/warehouse/edit/multiple/:template/:id?",
    name: "warehouseEditMultiple",
    props: true,
    component: () =>
      import("@shared/warehouse/views/WarehouseEditMultiple.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "inventoryManagement",
          route: { name: "warehouseList" },
        },
      ],
    },
  },
  {
    path: "/warehouse/articles-search",
    name: "warehouseArticleSearch",
    props: true,
    component: () =>
      import("@shared/warehouse/views/WarehouseArticleSearch.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "articleSearch",
          route: { name: "warehouseArticleSearch" },
        },
      ],
    },
  },
  {
    path: "/warehouse/edit/single-article/:template/:id?",
    name: "warehouseEditSingleArticle",
    props: true,
    component: () =>
      import("@shared/warehouse/views/WarehouseEditSingleArticle.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "inventoryManagement",
          route: { name: "warehouseList" },
        },
      ],
    },
  },

  {
    path: "/warehouse/reports/",
    name: "warehouseReportsOverview",
    component: () =>
      import("@shared/warehouse/views/WarehouseReportsOverview.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "reports",
          route: { name: "warehouseReportsOverview" },
        },
      ],
    },
  },

  {
    path: "/warehouse/reports/assets",
    name: "warehouseReports",
    component: () => import("@shared/warehouse/views/ReportWarehouse.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "assets",
          route: { name: "assetReports" },
        },
      ],
    },
  },
  {
    path: "/warehouse/reports/changelogs",
    name: "warehouseChangelogs",
    component: () =>
      import("@shared/warehouse/views/ReportWarehouseChangelog.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "reports",
          route: { name: "warehouseReportsOverview" },
        },
        {
          label: "changelog",
          route: { name: "warehouseChangelogs" },
        },
      ],
    },
  },
  {
    path: "/warehouse/access",
    name: "warehouseAccess",
    component: () => import("@shared/warehouse/views/WarehouseAccess.vue"),
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "accessRightsWarehouse",
          route: { name: "warehouseAccess" },
        },
      ],
    },
  },
  {
    path: "/warehouse/notifications",
    name: "whNotifications",
    component: () => import("./views/WarehouseNotifications.vue"),
    props: true,
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "notifications",
          route: { name: "whNotifications" },
        },
      ],
    },
  },
  {
    path: "/warehouse/notifications/:id",
    name: "whNotificationDetail",
    component: () => import("./views/WarehouseNotificationDetail.vue"),
    props: true,
    meta: {
      appClientId: clientId,
      breadcrumbs: [
        {
          label: "notifications",
          route: { name: "whNotifications" },
        },
      ],
    },
  },
];
